import { motion } from "framer-motion"
import React, { useEffect, useState } from "react"
import styled from "styled-components"
import moment from "moment"

const Container = styled(motion.div)`
  ${({ theme }) => `
    align-items: center;
    display: flex;
    flex-direction: column;
    font-size: ${theme.fontSize.rg};
  `}
`

const CountdownContainer = styled.div`
  ${({ theme }) => `
    color: white;
    display: flex;
    font-family: ${theme.fonts.proxima};
    font-size: inherit;
  `}
`

const CountContainer = styled.div`
  align-items: center;
  color: inherit;
  display: flex;
  flex-direction: column;
  font-family: inherit;
  justify-content: center;
  max-width: 4rem;
  width: 4rem;
`

const Text = styled.div`
  ${({ theme }) => `
    color: inherit;
    font-family: inherit;
    font-size: inherit;
    margin-bottom: ${theme.spaces[0]};
    text-shadow: 2px 2px 5px #020202;
  `}
`

const BigText = styled(Text)`
  ${({ theme }) => `
    font-size: ${theme.fontSize.xl};
    margin-bottom: 0px;
  `}
`

const textVariant = {
  hidden: { opacity: 0, y: -25 },
  visible: { opacity: 1, y: 0 },
}

const getDifference = countdownTo => {
  const now = moment(new Date())
  const end = moment(countdownTo, "YYYY-MM-DD:hh:mm:ss")
  const duration = moment.duration(end.diff(now))

  return {
    s: duration.seconds(),
    m: duration.minutes(),
    h: duration.hours(),
    d: end.diff(now, "days"),
  }
}

const countDownDate = "2021-8-8:12:00:00"

const Countdown = () => {
  const [duration, setDuration] = useState(getDifference(countDownDate))
  const countdownOver =
    duration.s <= 0 && duration.m <= 0 && duration.h <= 0 && duration.d <= 0

  useEffect(() => {
    if (!countdownOver) {
      const timer = setTimeout(() => {
        setDuration(getDifference(countDownDate))
      }, 1000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [duration, countdownOver])

  return (
    <Container
      animate="visible"
      initial="hidden"
      variants={textVariant}
      transition={{ delay: 1.75, ease: "linear" }}
    >
      {!countdownOver && (
        <>
          <Text>coming soon</Text>
          <CountdownContainer>
            <CountContainer>
              <BigText>{duration.d}</BigText>
              <Text>days</Text>
            </CountContainer>
            <BigText>:</BigText>
            <CountContainer>
              <BigText>{duration.h}</BigText>
              <Text>hours</Text>
            </CountContainer>
            <BigText>:</BigText>
            <CountContainer>
              <BigText>{duration.m}</BigText>
              <Text>mins</Text>
            </CountContainer>
            <BigText>:</BigText>
            <CountContainer>
              <BigText>{duration.s}</BigText>
              <Text>secs</Text>
            </CountContainer>
          </CountdownContainer>
        </>
      )}
    </Container>
  )
}

export default Countdown
