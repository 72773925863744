import React from "react"
import styled from "styled-components"
import SectionHeader from "./SectionHeader"
import CandidPhoto from "../images/class-photo-candid.jpg"
import FormalPhoto from "../images/class-photo-formal.jpg"

const Container = styled.div`
  display: flex;
  width: inherit;
  flex-direction: column;
`

const MessageParagraph = styled.div`
  ${({ theme }) => `
    font-size: ${theme.fontSize.rg};
    font-family: ${theme.fonts.proxima};
    text-align: justify;
    margin-bottom: ${theme.spaces[2]};
    margin-top: ${theme.spaces[1]};
  `}
`

const Text = styled.div`
  ${({ theme }) => `
    margin-bottom: ${theme.spaces[2]};
    font-family: inherit;
  `}
`

const PhotoContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    width: inherit;
    margin-bottom: ${theme.spaces[2]};
    flex-direction: column;
    height: 25rem;

    & > div:first-child {
      margin-bottom: ${theme.spaces[2]};
    }

    @media (min-width: ${theme.breakpoints[1]}) {
      flex-direction: row;
      height: fit-content;

      & > div:first-child {
        margin-right: ${theme.spaces[2]};
      }
    }
  `}
`

const ClassPhoto = styled.div`
  ${({ theme, src }) => `
    display: flex;
    flex: 1;
    background-image: url("${src}");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

    @media (min-width: ${theme.breakpoints[1]}) {
      background-size: cover;
      height: 17rem;
    }
  `}
`

const MessageFromGraduates = () => {
  return (
    <Container>
      <SectionHeader>Message from the graduates</SectionHeader>
      <MessageParagraph>
        <Text>Pagbati!</Text>
        <Text>
          Greetings from the University of the Philippines College of Medicine
          Class of 2021!
        </Text>
        <Text>
          We thank you for joining us in today’s celebration of our past five
          years in the UP College of Medicine.
        </Text>
        <Text>
          Much like our graduation’s theme — Salimbay, meaning to take flight
          and to glide comfortably without the thrust of flapping wings — our
          spirits are high as we revel in this brief moment of weightlessness:
          where there is nothing but shared fulfillment in what we have
          accomplished and excitement for what is yet to come.
        </Text>
        <Text>
          But in the same way that a bird must beat its wings furiously to
          generate enough thrust, overcome the air pressure, and create lift, we
          too have spent the past five years in an arduous struggle. Every day
          for the last five years, we have had to persevere and overcome the
          weight of every challenge thrown our way. We have had to swoop and
          swerve our way around setbacks and fight like hell to keep from
          plummeting to the ground.{" "}
        </Text>
        <Text>
          It was not easy, but we are fortunate to have had each other to lean
          on. The incredible mentors, patients, and fellow healthcare workers
          we’ve met along the way helped to lighten the load. And when we felt
          lost or in the dark, we needed only to be reminded of our “Why” — of
          the promise to render service for the underserved — to find our way.
        </Text>
        <Text>
          The relentless work to realize a dream and the energy invested to
          achieve a goal have culminated in today’s graduation rites. And
          today’s rites is the first time in five years that we can take a break
          from the flap to savor the glide.
        </Text>
        <Text>
          We look forward to commemorating this milestone with you — the first
          day we can finally, truly say that XXI is <i>Soaring High</i>.
        </Text>
      </MessageParagraph>
      <PhotoContainer>
        <ClassPhoto src={FormalPhoto} />
        <ClassPhoto src={CandidPhoto} />
      </PhotoContainer>
    </Container>
  )
}

export default MessageFromGraduates
