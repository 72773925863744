import React from "react"
import styled from "styled-components"

const PadletContainer = styled.div`
  box-sizing: border-box;
  overflow: hidden;
  position: relative;
  width: 100%;
  background: #f4f4f4;
  margin-bottom: 1rem;
`

const PadletFrame = styled.iframe`
  width: 100%;
  height: 608px;
  display: block;
  padding: 0;
  margin: 0;
`

const Padlet = () => (
  <PadletContainer>
    <PadletFrame
      src="https://padlet.com/embed/83pze4sb58sgq2zi"
      frameBorder="0"
      allow="camera;microphone;geolocation"
    ></PadletFrame>
  </PadletContainer>
)

export default Padlet
