import React from "react"
import { motion } from "framer-motion"
import Countdown from "../components/Countdown"
import FlyingBird from "../images/flying-bird.png"
import FoldContainer from "../components/FoldContainer"
import GlidingBird from "../images/gliding-bird.png"
import Hero from "../components/Hero"
import HeroBackground from "../images/hero-background.jpg"
import MarginContainer from "../components/MarginContainer"
import MessageFromGraduates from "../components/MessageFromGraduates"
import PerchedBird from "../images/perched-bird.png"
import SalimbayGif from "../images/salimbay.gif"
import styled from "styled-components"
import UpwardBird from "../images/upward-bird.png"
import Padlet from "../components/Padlet"

const SalimbayContainer = styled.div`
  position: relative;
  display: block;
  margin: auto;
`

const SalimbayImage = styled(motion.img)`
  ${({ theme }) => `
    width: 20rem;
    margin: ${theme.spaces[3]} 0;

    @media (min-width: ${theme.breakpoints[2]}){
      width: 35rem;
      margin: ${theme.spaces[3]} 0;
    }
  `}
`

const HeroContainer = styled.div`
  color: white;
  display: flex;
  padding-top: calc(3rem + 30px);
  width: inherit;
  height: calc(100% - 3rem - 30px);
  align-items: center;
  justify-content: center;
`

const HeroContentContainer = styled.div`
  ${({ theme }) => `
    display: flex;
    width inherit;
    flex-direction: column;
    font-family: ${theme.fonts.proxima};
  `}
`

const UPCMContainer = styled(motion.div)`
  ${({ theme }) => `
    color: inherit;
    font-family: inherit;
    font-size: ${theme.fontSize.rg};
    margin-bottom: ${theme.spaces[0]};
    text-align: center;
    text-shadow: 2px 2px 5px #020202;
    text-transform: uppercase;
    width: 100%;
  `}
`

const PresentText = styled(UPCMContainer)`
  ${({ theme }) => `
    font-size: ${theme.fontSize.rg};
    margin-bottom: ${theme.spaces[3]};
    text-shadow: 2px 2px 5px #020202;
    text-transform: lowercase;
  `}
`

const PerchedBirdImage = styled(motion.img)`
  ${({ theme }) => `
    position: absolute;
    right: 0.5rem;
    top: 3rem;
    width: 1.5rem;
    z-index: 1;

    @media (min-width: ${theme.breakpoints[2]}){
      right: 1rem;
      top: 3.5rem;
      width: 2.5rem;
    }
  `}
`

const UpwardBirdImage = styled(motion.img)`
  ${({ theme }) => `
    position: absolute;
    right: 7rem;
    top: 15rem;
    width: 1.75rem;
    z - index: 1;
    @media(min - width: ${theme.breakpoints[2]}) {
      right: 8rem;
      top: 14rem;
    }
  `}
`

const GlidingBirdImage = styled(motion.img)`
  ${({ theme }) => `
    position: absolute;
    right: 4rem;
    top: 10rem;
    width: 1.75rem;
    z - index: 1;
    @media(min - width: ${theme.breakpoints[2]}) {
      right: 4rem;
      top: 10rem;
    }
  `}
`

const FlyingBirdImage = styled(motion.img)`
  ${({ theme }) => `
    position: absolute;
    right: 2rem;
    top: 15rem;
    width: 1.75rem;
    z - index: 1;
    @media(min - width: ${theme.breakpoints[2]}) {
      right: 2rem;
      top: 15rem;
    }
  `}
`

const Congratulations = styled.div`
  ${({ theme }) => `
    font-family: ${theme.fonts.andellia};
    font-size: ${theme.fontSize.xxl};
    color: ${theme.colors.blue};
    text-align: center;
    margin-bottom: ${theme.spaces[2]};
  `}
`

const birdVariantLeft = {
  hidden: { opacity: 0, x: -25 },
  visible: { opacity: 1, x: 0 },
}

const birdVariantRight = {
  hidden: { opacity: 0, x: 25 },
  visible: { opacity: 1, x: 0 },
}

const textVariant = {
  hidden: { opacity: 0, scale: 0.9 },
  visible: { opacity: 1, scale: 1 },
}

const birdVariant = {
  hidden: { opacity: 0 },
  visible: { opacity: 1 },
}

const IndexPage = () => {
  const preloadImages = [
    HeroBackground,
    SalimbayGif,
    UpwardBird,
    GlidingBird,
    FlyingBird,
  ]

  return (
    <>
      <Hero preloadImages={preloadImages} backgroundImage={HeroBackground}>
        <MarginContainer>
          <HeroContainer>
            <HeroContentContainer>
              <UPCMContainer
                animate="visible"
                initial="hidden"
                variants={textVariant}
              >
                UP college of Medicine Class of 2021
              </UPCMContainer>
              <PresentText
                animate="visible"
                initial="hidden"
                variants={textVariant}
                transition={{ delay: 0.25, ease: "linear" }}
              >
                presents
              </PresentText>
              <SalimbayContainer>
                <SalimbayImage
                  src={SalimbayGif}
                  animate="visible"
                  initial="hidden"
                  variants={birdVariant}
                  transition={{ delay: 0.5, ease: "linear" }}
                />
                <PerchedBirdImage
                  src={PerchedBird}
                  animate="visible"
                  initial="hidden"
                  variants={birdVariant}
                  transition={{ delay: 1.25, ease: "linear" }}
                />
              </SalimbayContainer>
              <Countdown />
            </HeroContentContainer>
          </HeroContainer>
          <UpwardBirdImage
            src={UpwardBird}
            animate="visible"
            initial="hidden"
            variants={birdVariantLeft}
            transition={{ ease: "easeIn", delay: 1.25 }}
          />
          <GlidingBirdImage
            src={GlidingBird}
            animate="visible"
            initial="hidden"
            variants={birdVariantLeft}
            transition={{ ease: "easeIn", delay: 1.25 }}
          />
          <FlyingBirdImage
            src={FlyingBird}
            animate="visible"
            initial="hidden"
            variants={birdVariantRight}
            transition={{ ease: "easeIn", delay: 1.25 }}
          />
        </MarginContainer>
      </Hero>
      <FoldContainer>
        <MarginContainer>
          <MessageFromGraduates />
        </MarginContainer>
      </FoldContainer>
      <FoldContainer id="padlet">
        <MarginContainer>
          <Padlet />
          <Congratulations>
            Congratulations, UPCM Class of 2021!
          </Congratulations>
        </MarginContainer>
      </FoldContainer>
    </>
  )
}

export default IndexPage
